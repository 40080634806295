<template>
    <div :class="[activa ? 'activa' : '' ,'search-producto']">
        <div class="search-producto__header">
            <i class="icon-cancel text-white f-20 mr-4 cr-pointer" @click="toggle" />
            <h1 class="text-white f-17 m-0">
                Buscar producto para añadir
            </h1>
        </div>
        <div class="search-producto__body">
            <el-input v-model="buscar" clearable placeholder="Buscar producto" class="br-20 my-2" @input="buscarProductosRecurrentes" />
            <div class="search-producto__layout custom-scroll">
                <div v-for="(producto,i) in productos" :key="i" class="card-producto">
                    <figure class="card-producto__figure bg-whitesmoke">
                        <img :src="producto.imagen_firmada" class="card-producto__img" style="width:38px; height: 53px;" />
                    </figure>
                    <div class="card-producto__desc">
                        <p class="card-producto__nombre text-general f-14 ">{{ producto.sku }} - {{ producto.nombre }}</p>
                        <p class="card-producto__presentacion f-14">{{ producto.presentacion }}</p>
                        <div class="d-flex align-items-center">
                            <p class="card-producto__precio text-general f-14 f-500 mr-3">{{ `${separadorNumero(producto.valor)}` }}</p>
                            <p v-if="producto.id_promocion" class="card-producto__precio-descuento f-14 f-500">{{ `${separadorNumero(producto.promo_valor)}` }} </p>
                        </div>
                        <p v-if="producto.id_promocion" class="card-producto__descuento bg-leeche">{{ producto.texto }} </p>
                    </div>
                    <i class="icon-plus-squared text-leeche f-25 cr-pointer" @click="gestionRecurrenteProducto(producto.id)" />
                </div>
                <div class="d-middle-center px-3 text-center" style="height:40vh">
                    <div v-if="buscar === ''" class="">
                        <p class="f-600 f-17">¿Qué producto estás buscando?</p>
                        <img height="115" width="115" src="/img/modales/magnifying_smile.svg" alt="" />
                    </div>
                    <div v-if="productos.length === 0 && buscar!== '' " class="">
                        <p class="f-600 f-17">No hemos encontrador coincidencias con tu búsqueda</p>
                        <img height="115" width="115" src="/img/modales/magnifying_sad.svg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PedidosRecurrentes from '~/services/almacen/pedidos_recurrentes'
export default {
    data(){
        return{
            pedidoActivo: null,
            activa: false,
            buscar: '',
            productos:[]
        }
    },
    methods:{
        toggle(pedidoActivo){
            this.pedidoActivo = pedidoActivo
            this.activa = !this.activa
        },
        async buscarProductosRecurrentes(query){
            try {
                if(query.length > 2){
                    const busqueda = async() => {
                        let params = {
                            query
                        }
                        const {data} = await PedidosRecurrentes.buscarProductosRecurrentes(this.pedidoActivo,params)
                        this.productos = data.productos
                    }
                    await this.delay(busqueda)
                }else if(query.length === 0){
                    this.productos = []
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async gestionRecurrenteProducto(id){
            try {
                let model = {
                    id_recurrente: this.pedidoActivo,
                    id_producto: id,
                    cantidad: 1,
                }
                const {data} = await PedidosRecurrentes.gestionRecurrenteProducto(model)
                this.notificacion('Mensaje', data.message, 'success')
                // this.activa = !this.activa
                this.$emit('agregarProducto', data.data)

                this.buscarProductosRecurrentes(this.buscar)

            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .search-producto{
        width: 375px;
        box-shadow: 5px 0px 15px rgba(0,0,0,.3);
        position: absolute;
        display: none;
        animation-duration: .2s;
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        top: 0px;
        bottom: 0;
        right: -375px;
        z-index: 2;
        &__header{
            height: 57px;
            background: black;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0px 16px;
            box-shadow: 0px 3px 4px rgba(0,0,0,.2);
        }
        &__body{
            text-align: center;
            padding:  0px 14px;
            height: calc(100% - 57px);
            position: relative;
            z-index: 2;
            background: #fff;
        }
        &__layout{
            overflow: auto;
           height: calc(100% - 80px);
        }
        &__input{
            height: 44px;
            border-radius: 247px;
            border: 1px solid #dee2e6;
            padding: 20px;
            margin: 14px 0px;
            width: 100%;
            outline:0;
            &::placeholder{
                color: #dee2e6;

            }
        }
}
            .card-producto{
                width:336px;
                height: 117px;
                padding: 14px 0;
                display: flex;
                align-items: center;
                &__figure{
                    width: 89px ;
                    height: 89px ;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    border-radius: 12px;
                }

                &__img{
                    object-fit: contain;
                }
                &__desc{
                    text-align: start;
                    margin: 0px 10px;
                }
                &__nombre{
                    font-size: 14px;
                    margin:0 0 15px 0 !important;
                    flex-wrap: wrap;
                }
                &__presentacion{
                    color: #707070;
                }
                &__descuento{
                    border-radius: 22px;
                    font-size: 11px;
                    color: #fff;
                    width: fit-content;
                    height: 17px;
                    text-align: center;
                    text-transform: uppercase;
                    padding:  2px 10px 0;
                }
                &__precio-descuento{
                    text-decoration: line-through;
                    color: #dee2e6;
                }
            }
    .activa{
        display: block;
      animation-name:   buscar;

    }
    @keyframes buscar{
        0%{
            opacity: 0;
        }
        100% {
            opacity: 1;
            right: 0;
        }
    }
</style>
